//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmpMainMenu from '~/components/common/layout/EmpMainMenu';

document.querySelector('body').classList.add('loaded');

export default {
    components: {
        EmpMainMenu,
    },
    data: function () {
        return {
            isHome: {
                type: Boolean,
                default: true,
            },
            isFullwidth: {
                type: Boolean,
                default: false,
            },
            uri: window.location.pathname,

            fullName: localStorage.getItem('fullName'),
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher',
            done: false,
        };
    },
    watch: {
        $route: function () {
            this.uri = window.location.pathname;
            if (this.$route.path.includes('fullwidth')) {
                this.isFullwidth = true;
            } else this.isFullwidth = false;
        },
    },
    props: {
        chatCount: 0,
        notificationCount: 0,
    },
    computed: {
        emp() {
            return window.location.href.indexOf('/employer-porta') > -1;
        },
    },
    mounted: function () {
        if (this.$route.path.includes('fullwidth')) {
            this.isFullwidth = true;
        } else this.isFullwidth = false;
        const myImage = new Image();
        myImage.src = '@/static/images/e/employer.webp';
        myImage.onload = () => {
            this.done = true;
        };
        myImage.onerror = () => {
            this.done = true;
        };
    },
    methods: {
        showMobileMenu: function () {
            document.querySelector('body').classList.add('mmenu-active');
        },
        showMobileSearch: function (e) {
            let headerSearch = e.currentTarget.closest('.header-search');
            headerSearch.classList.add('show');
            headerSearch
                .querySelector('.header-search-wrapper')
                .classList.add('show');
        },
    },
};
