

export default function(to, from, savedPosition) {
      if (savedPosition) {
        return { x: 0, y: 200, behavior: 'instant' };
        //return savedPosition
      } else {
        if (to.hash) {
          return {
            el: to.hash
          };
        } else
        if (!to.path.endsWith('career-advice') && !to.path.endsWith('recruitment-advice'))
        return { x: 0, y: 0, behavior: 'instant' };
        return { x: 0, y: 200, behavior: 'instant' };
      }
    }
