//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PvCarousel from '~/components/features/PvCarousel';

export default {
    components: {
        PvCarousel
    },
    props: {
        location: {
            type: String,
            default: 'home'
        }
    },
    data: function() {
        return {
            ads: [],
            adsSlider: {
                slidesPerView: 6,
                spaceBetween: 20,
                breakpoints: {
                    480: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    1200: {
                        slidesPerView: 4
                    }
                }
            }
        };
    },
    mounted() {
        this.getBottomAds();
    },
    methods: {
        async getBottomAds() {
            var R = await this.$GetReq('banner-advert/' + this.location, {
                Target: 3,
                InstitutionCategory: this.$getInstitutionCategoryId()
            });
            for (var i = 0; i < R.data.length; i++) {
                if (R.data[i].Target == 3) {
                    this.ads.push(R.data[i]);
                }
            }
        },
        async bannerClick(adId) {
            if (!adId) {
                return;
            }
            var R = await this.$PostReq('banner-advert/click', {
                Id: adId
            });

            if (R && R.data) {
                window.open(R.data, '_blank');
            }
        }
    }
};
