//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        location: {
            type: String,
            default: 'home'
        },
        left: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            ads: []
        };
    },
    mounted() {
        this.getSideAds();
    },
    methods: {
        async getSideAds() {
            var Target = 1;
            if (this.right) {
                Target = 1;
            }
            if (this.left) {
                Target = 2;
            }
            var R = await this.$GetReq('banner-advert/' + this.location, {
                InstitutionCategory: this.$getInstitutionCategoryId(),
                Target: Target
            });
            for (var i = 0; i < R.data.length; i++) {
                this.ads.push(R.data[i]);
                // if (this.right && R.data[i].Target == 1) {
                //     this.ads.push(R.data[i]);
                // }
                // if (this.left && R.data[i].Target == 2) {
                //     this.ads.push(R.data[i]);
                // }
            }
        },
        async bannerClick(adId) {
            if (!adId) {
                return;
            }
            var R = await this.$PostReq('banner-advert/click', {
                Id: adId
            });
            if (R && R.data) {
                window.open(R.data, '_blank');
            }
        }
    }
};
