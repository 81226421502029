import { getInstitutionCategoryId, extrabaseLogic, getInstitutionCategoryUrl, foc, isEmployer, isSeeker, canAccess, canAccessProduct, ckconfig, youtube_parser } from "~/utilities/helpers";
export default (context, inject) => {
    inject('getInstitutionCategoryId', getInstitutionCategoryId);
    inject('extrabaseLogic', extrabaseLogic);
    inject('getInstitutionCategoryUrl', getInstitutionCategoryUrl);
    inject('foc', foc);
    inject('isEmployer', isEmployer);
    inject('isSeeker', isSeeker);
    inject('canAccess', canAccess);
    inject('canAccessProduct', canAccessProduct);
    inject('ckconfig', ckconfig);
    inject('youtube_parser', youtube_parser);
}