var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header bg-light"},[_c('div',{staticClass:"row "},[(!_vm.done)?_c('div',{staticClass:"col-md-12",staticStyle:{"height":"100%","background-color":"#e8f2f5"}}):_c('div',{staticClass:"col-md-12"},[(_vm.extrabase == 'further')?_c('img',{staticClass:"slide-bg w-100",attrs:{"src":require("@/static/images/e/further.webp"),"alt":"Edyougo","loading":"lazy"}}):(_vm.extrabase == 'school')?_c('img',{staticClass:"slide-bg w-100",attrs:{"src":require("@/static/images/e/school.webp"),"alt":"Edyougo","loading":"lazy"}}):_c('img',{staticClass:"slide-bg w-100",attrs:{"src":require("@/static/images/e/higher.webp"),"alt":"Edyougo","loading":"lazy"}})])]),_vm._v(" "),_c('div',{staticClass:"header-middle text-dark p-0",class:_vm.$route.path === '/'
                ? 'sticky-header mobile-sticky'
                : 'sticky-header mobile-sticky'},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-left w-auto"},[_c('button',{staticClass:"mobile-menu-toggler mr-2",attrs:{"type":"button","aria-label":"show Mobile Menu"},on:{"click":_vm.showMobileMenu}},[_c('i',{staticClass:"fas fa-bars"})]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo d-lg-none",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("static/images/logo.webp"),"width":"100","height":"23.5","alt":"EduJob Logo"}})])],1),_vm._v(" "),_c('div',{staticClass:"header-right w-auto pr-0 d-lg-none"},[(_vm.$isSeeker())?_c('div',{staticClass:"header-dropdown mr-auto mr-sm-3 mr-md-0"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"header-menu"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":"/job-seeker/dashboard"}},[_vm._v("Dashboard")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":"/job-seeker/applications"}},[_vm._v("Applications")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":"/job-seeker/profile"}},[_vm._v("Profile")])],1),_vm._v(" "),(_vm.$isSeeker())?_c('li',[_c('nuxt-link',{attrs:{"to":"/job-seeker/job-alerts","title":"Job Alerts"}},[_c('span',{staticClass:"badge-count"},[_vm._v("Job Alerts ["+_vm._s(_vm.jobAlertCount)+"]")])])],1):_vm._e(),_vm._v(" "),(_vm.$isSeeker())?_c('li',{class:{
                                    activeli: _vm.uri.includes('wishlist')
                                }},[_c('nuxt-link',{attrs:{"to":"/job-seeker/wishlist","title":"Wishlist"}},[_c('i',{staticClass:"fas fa-heart"}),_vm._v(" "),_c('span',{staticClass:"badge-count"},[_vm._v("["+_vm._s(_vm.wishlistCount)+"]")])])],1):_vm._e(),_vm._v(" "),(_vm.$isSeeker())?_c('li',{class:{
                                    activeli: _vm.uri.includes(
                                        'messaging-center'
                                    )
                                }},[_c('nuxt-link',{attrs:{"to":"/job-seeker/messaging-center","title":"Messaging Centre"}},[_c('i',{staticClass:"fas fa-envelope"}),_vm._v(" "),_c('span',{staticClass:"badge-count"},[_vm._v("["+_vm._s(_vm.chatCount)+"]")])])],1):_vm._e(),_vm._v(" "),(_vm.$isSeeker())?_c('li',{class:{
                                    activeli: _vm.uri.includes('notifications')
                                }},[_c('nuxt-link',{attrs:{"to":"/job-seeker/notifications","title":"Notifications"}},[_c('i',{staticClass:"fas fa-bell"}),_vm._v(" "),_c('span',{staticClass:"badge-count"},[_vm._v("["+_vm._s(_vm.notificationCount)+"]")])])],1):_vm._e()])])]):(_vm.$isEmployer())?_c('div',{staticClass:"header-dropdown mr-auto mr-sm-3 mr-md-0"},[_c('a',{attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#logout2loginModal"}},[_vm._v("\n                        Login\n                    ")])]):_c('nuxt-link',{attrs:{"to":"/auth/job-seeker/"}},[_vm._v(" Login ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"m-4 container d-none d-lg-block mlmrauto"},[_c('nav',{staticClass:"main-nav w-100"},[_c('ul',{staticClass:"menu main-menu sf-arrows"},[_c('li',{staticClass:"mr-5"},[_c('nuxt-link',{staticClass:"rounded-pill",class:{
                            fadeinout: !_vm.emp && 'higher' == _vm.extrabase
                        },staticStyle:{"color":"#0f4170 !important","border":"1px solid #0f4170","padding":"2rem","font-size":"1.5rem"},attrs:{"to":"/higher","title":"Higher Education"}},[_c('i',{staticClass:"fa fa-globe me-2"}),_vm._v(" "),_c('span',[_vm._v("Further Education")])])],1),_vm._v(" "),_c('li',{staticClass:"mr-5"},[_c('nuxt-link',{staticClass:"rounded-pill",class:{
                            fadeinout: !_vm.emp && 'further' == _vm.extrabase
                        },staticStyle:{"color":"#4d377b !important","border":"1px solid #4d377b","padding":"2rem","font-size":"1.5rem"},attrs:{"to":"/further","title":"Further Education"}},[_c('i',{staticClass:"fa fa-globe me-2"}),_vm._v("\n                        Further Education\n                    ")])],1),_vm._v(" "),_c('li',{staticClass:"mr-5"},[_c('nuxt-link',{staticClass:"rounded-pill",class:{
                            fadeinout: !_vm.emp && 'school' == _vm.extrabase
                        },staticStyle:{"color":"#255b68 !important","border":"1px solid #255b68","padding":"2rem","font-size":"1.5rem"},attrs:{"to":"/school","title":"School Education"}},[_c('i',{staticClass:"fa fa-globe me-2"}),_vm._v("\n                        School Education\n                    ")])],1),_vm._v(" "),_c('li',{staticClass:"current-nav-area mr-2"},[_c('nuxt-link',{staticClass:"rounded-pill",class:{
                            fadeinout: _vm.emp
                        },staticStyle:{"color":"#2d363b !important","border":"1px solid #2d363b","padding":"2rem","font-size":"1.5rem"},attrs:{"to":"/employer-portal/","title":"Employer Portal"}},[_c('i',{staticClass:"fa fa-globe me-2"}),_vm._v("\n                        Employer Portal\n                    ")])],1)])])]),_vm._v(" "),_c('div',{staticClass:"header-bottom sticky-header desktop-sticky d-none d-lg-block e-primary-bg",class:_vm.isFullwidth ? 'bg-primary e-primary-bg' : ''},[_c('div',{staticClass:"container"},[_c('main-menu',{attrs:{"chatCount":_vm.chatCount,"wishlistCount":_vm.wishlistCount,"jobAlertCount":_vm.jobAlertCount,"notificationCount":_vm.notificationCount}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"javascript:;"}},[_c('span',[_vm._v(" Welcome ")])])}]

export { render, staticRenderFns }