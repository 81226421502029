//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmpHeaderSection from '~/components/common/layout/EmpHeaderSection';
import FooterSection from '~/components/common/layout/FooterSection';
import EmpMobileMenu from '~/components/common/layout/EmpMobileMenu';
import LogoutToLogin from '~/components/auth/LogoutToLogin';

import { VueSlideToggle } from 'vue-slide-toggle';
import {
    scrollTop,
    stickyHeaderHandler,
    resizeHandler,
    showScrollTopHandler,
} from '~/utilities/browser.js';

export default {
    head() {
        return {
            link: [
                {
                    rel: 'stylesheet',
                    href: '/css/shared.css',
                    async: true,
                    defer: true,
                },
                {
                    rel: 'stylesheet',
                    href: '/css/layout/employer.css',
                    async: true,
                    defer: true,
                },
            ],
        };
    },
    components: {
        EmpHeaderSection,
        FooterSection,
        EmpMobileMenu,
        VueSlideToggle,
        LogoutToLogin,
    },
    data: function () {
        return {
            advertControlOpen: false,
            performanceOpen: false,
            profileOpen: false,

            chatCount: 0,
            notificationCount: 0,
        };
    },

    watch: {
        $route: function () {
            resizeHandler();
            this.$modal.hideAll();
        },
    },
    mounted: function () {
        if (localStorage.getItem('email')) {
            window.setInterval(() => {
                if (this.$isEmployer()) {
                    this.getChatCount();
                    this.getNotificationsCount();
                }
            }, 30000);
        }
        window.addEventListener('scroll', stickyHeaderHandler, {
            passive: true,
        });
        window.addEventListener('scroll', showScrollTopHandler, {
            passive: true,
        });
        window.addEventListener('resize', stickyHeaderHandler);
        window.addEventListener('resize', resizeHandler);
    },
    destroyed: function () {
        window.removeEventListener('scroll', showScrollTopHandler, {
            passive: true,
        });
        window.removeEventListener('scroll', stickyHeaderHandler, {
            passive: true,
        });
        window.removeEventListener('resize', stickyHeaderHandler);
        window.removeEventListener('resize', resizeHandler);
    },
    methods: {
        async getChatCount() {
            var R = await this.$GetReq(
                'in-app-conversation/new-messages-count'
            );
            if (R) {
                if (R.success) {
                    this.chatCount = R.data;
                }
            }
        },
        async getNotificationsCount() {
            // var R = await this.$GetReq(
            //     'employer/notification-log/new-notifications-count' //TODO
            // );
            // if (R) {
            //     if (R.success) {
            //         this.notificationCount = R.data;
            //     }
            // }
        },
        scrollToTop: function () {
            scrollTop(false);
        },
        hideMobileSearch: function () {
            if (document.querySelector('.header-search.header-search-inline')) {
                let headerSearch = document.querySelector(
                    '.header-search.header-search-inline'
                );
                headerSearch.classList.remove('show');
                headerSearch
                    .querySelector('.header-search-wrapper')
                    .classList.remove('show');
            }
            if (document.querySelector('.search-suggests')) {
                document
                    .querySelector('.search-suggests')
                    .setAttribute('style', 'display: none');
            }
        },
    },
};
