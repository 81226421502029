//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data: function() {
        return {};
    },
    props: {
        active: '',
        mid: null,
        midUrl: null,
        home: {
            type: String,
            default: '/'
        }
    }
};
