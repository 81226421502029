import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
    }
});

Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY - H:m');
    }
});

Vue.filter('nowDaysDiff', function (value) {
    if (value) {
        let date_1 = new Date(value);
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays + ' day(s)';
    }
});

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
})