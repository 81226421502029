import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d4e6ae84 = () => interopDefault(import('..\\pages\\public\\about.vue' /* webpackChunkName: "" */))
const _00388c2a = () => interopDefault(import('..\\pages\\public\\career-advice\\index.vue' /* webpackChunkName: "" */))
const _67824591 = () => interopDefault(import('..\\pages\\public\\contact.vue' /* webpackChunkName: "" */))
const _5cae0c0a = () => interopDefault(import('..\\pages\\employer-portal\\index.vue' /* webpackChunkName: "pages/employer-portal/index" */))
const _110a1c19 = () => interopDefault(import('..\\pages\\public\\employers\\index.vue' /* webpackChunkName: "" */))
const _bd20d3f2 = () => interopDefault(import('..\\pages\\public\\faq.vue' /* webpackChunkName: "" */))
const _a75eebfc = () => interopDefault(import('..\\pages\\public\\job-adverts\\index.vue' /* webpackChunkName: "" */))
const _644405c9 = () => interopDefault(import('..\\pages\\auth\\employer\\index.vue' /* webpackChunkName: "pages/auth/employer/index" */))
const _5fc7188f = () => interopDefault(import('..\\pages\\auth\\job-seeker\\index.vue' /* webpackChunkName: "pages/auth/job-seeker/index" */))
const _46f12692 = () => interopDefault(import('..\\pages\\public\\career-advice\\write.vue' /* webpackChunkName: "" */))
const _0bdc5fe5 = () => interopDefault(import('..\\pages\\employer-portal\\account.vue' /* webpackChunkName: "pages/employer-portal/account" */))
const _3a7c63be = () => interopDefault(import('..\\pages\\employer-portal\\basket.vue' /* webpackChunkName: "pages/employer-portal/basket" */))
const _ef1dc184 = () => interopDefault(import('..\\pages\\employer-portal\\checkout.vue' /* webpackChunkName: "pages/employer-portal/checkout" */))
const _ce0f45cc = () => interopDefault(import('..\\pages\\employer-portal\\form-templates\\index.vue' /* webpackChunkName: "pages/employer-portal/form-templates/index" */))
const _0f1572a6 = () => interopDefault(import('..\\pages\\employer-portal\\info.vue' /* webpackChunkName: "pages/employer-portal/info" */))
const _855aed94 = () => interopDefault(import('..\\pages\\employer-portal\\messaging-center.vue' /* webpackChunkName: "pages/employer-portal/messaging-center" */))
const _43f5dd20 = () => interopDefault(import('..\\pages\\employer-portal\\notifications.vue' /* webpackChunkName: "pages/employer-portal/notifications" */))
const _0536ad0e = () => interopDefault(import('..\\pages\\employer-portal\\post-job-advert\\index.vue' /* webpackChunkName: "pages/employer-portal/post-job-advert/index" */))
const _351b00fe = () => interopDefault(import('..\\pages\\employer-portal\\pricing.vue' /* webpackChunkName: "pages/employer-portal/pricing" */))
const _3a597d3e = () => interopDefault(import('..\\pages\\employer-portal\\profile.vue' /* webpackChunkName: "pages/employer-portal/profile" */))
const _7952fd0e = () => interopDefault(import('..\\pages\\employer-portal\\purchase.vue' /* webpackChunkName: "pages/employer-portal/purchase" */))
const _3aea12be = () => interopDefault(import('..\\pages\\employer-portal\\recruitment-advice\\index.vue' /* webpackChunkName: "pages/employer-portal/recruitment-advice/index" */))
const _7acf142c = () => interopDefault(import('..\\pages\\job-seeker\\application-details.vue' /* webpackChunkName: "pages/job-seeker/application-details" */))
const _365ee064 = () => interopDefault(import('..\\pages\\job-seeker\\applications.vue' /* webpackChunkName: "pages/job-seeker/applications" */))
const _370c9cd3 = () => interopDefault(import('..\\pages\\job-seeker\\apply.vue' /* webpackChunkName: "pages/job-seeker/apply" */))
const _6ab25279 = () => interopDefault(import('..\\pages\\job-seeker\\dashboard.vue' /* webpackChunkName: "pages/job-seeker/dashboard" */))
const _1827eec6 = () => interopDefault(import('..\\pages\\job-seeker\\documents.vue' /* webpackChunkName: "pages/job-seeker/documents" */))
const _7bdb0412 = () => interopDefault(import('..\\pages\\job-seeker\\job-alerts.vue' /* webpackChunkName: "pages/job-seeker/job-alerts" */))
const _af3d922e = () => interopDefault(import('..\\pages\\job-seeker\\messaging-center.vue' /* webpackChunkName: "pages/job-seeker/messaging-center" */))
const _38ce444d = () => interopDefault(import('..\\pages\\job-seeker\\notifications.vue' /* webpackChunkName: "pages/job-seeker/notifications" */))
const _5f1fce4e = () => interopDefault(import('..\\pages\\job-seeker\\profile.vue' /* webpackChunkName: "pages/job-seeker/profile" */))
const _2ce840f0 = () => interopDefault(import('..\\pages\\job-seeker\\wishlist.vue' /* webpackChunkName: "pages/job-seeker/wishlist" */))
const _47f34c0e = () => interopDefault(import('..\\pages\\public\\policy\\cookies.vue' /* webpackChunkName: "" */))
const _07380887 = () => interopDefault(import('..\\pages\\public\\policy\\privacy.vue' /* webpackChunkName: "" */))
const _325084c6 = () => interopDefault(import('..\\pages\\public\\policy\\terms.vue' /* webpackChunkName: "" */))
const _734a18af = () => interopDefault(import('..\\pages\\auth\\employer\\activation.vue' /* webpackChunkName: "pages/auth/employer/activation" */))
const _8cbaa79c = () => interopDefault(import('..\\pages\\auth\\employer\\forget-password.vue' /* webpackChunkName: "pages/auth/employer/forget-password" */))
const _622f7ef2 = () => interopDefault(import('..\\pages\\auth\\employer\\reset-password.vue' /* webpackChunkName: "pages/auth/employer/reset-password" */))
const _f17bd3c8 = () => interopDefault(import('..\\pages\\auth\\employer\\success-registration.vue' /* webpackChunkName: "pages/auth/employer/success-registration" */))
const _3ac1d1a9 = () => interopDefault(import('..\\pages\\auth\\job-seeker\\activation.vue' /* webpackChunkName: "pages/auth/job-seeker/activation" */))
const _a33c9edc = () => interopDefault(import('..\\pages\\auth\\job-seeker\\change-email.vue' /* webpackChunkName: "pages/auth/job-seeker/change-email" */))
const _aa4d0110 = () => interopDefault(import('..\\pages\\auth\\job-seeker\\forget-password.vue' /* webpackChunkName: "pages/auth/job-seeker/forget-password" */))
const _7663aa28 = () => interopDefault(import('..\\pages\\auth\\job-seeker\\reset-password.vue' /* webpackChunkName: "pages/auth/job-seeker/reset-password" */))
const _6dca5e96 = () => interopDefault(import('..\\pages\\auth\\job-seeker\\success-registration.vue' /* webpackChunkName: "pages/auth/job-seeker/success-registration" */))
const _6ebca9c9 = () => interopDefault(import('..\\pages\\employer-portal\\applications\\dashboard.vue' /* webpackChunkName: "pages/employer-portal/applications/dashboard" */))
const _15ba2db7 = () => interopDefault(import('..\\pages\\employer-portal\\applications\\details.vue' /* webpackChunkName: "pages/employer-portal/applications/details" */))
const _444b2a6b = () => interopDefault(import('..\\pages\\employer-portal\\applications\\tracking-board.vue' /* webpackChunkName: "pages/employer-portal/applications/tracking-board" */))
const _d9d66c2a = () => interopDefault(import('..\\pages\\employer-portal\\edit-job-advert\\banner.vue' /* webpackChunkName: "pages/employer-portal/edit-job-advert/banner" */))
const _0aec052c = () => interopDefault(import('..\\pages\\employer-portal\\edit-job-advert\\text.vue' /* webpackChunkName: "pages/employer-portal/edit-job-advert/text" */))
const _1b96baf2 = () => interopDefault(import('..\\pages\\employer-portal\\form-templates\\edit.vue' /* webpackChunkName: "pages/employer-portal/form-templates/edit" */))
const _5d227982 = () => interopDefault(import('..\\pages\\employer-portal\\manage-job-advert\\banner-adverts.vue' /* webpackChunkName: "pages/employer-portal/manage-job-advert/banner-adverts" */))
const _d83e81d6 = () => interopDefault(import('..\\pages\\employer-portal\\manage-job-advert\\text-advert-details.vue' /* webpackChunkName: "pages/employer-portal/manage-job-advert/text-advert-details" */))
const _6a511dfa = () => interopDefault(import('..\\pages\\employer-portal\\manage-job-advert\\text-adverts.vue' /* webpackChunkName: "pages/employer-portal/manage-job-advert/text-adverts" */))
const _2064f25d = () => interopDefault(import('..\\pages\\employer-portal\\performance\\banner-advert.vue' /* webpackChunkName: "pages/employer-portal/performance/banner-advert" */))
const _20360b34 = () => interopDefault(import('..\\pages\\employer-portal\\performance\\banner-adverts.vue' /* webpackChunkName: "pages/employer-portal/performance/banner-adverts" */))
const _bfb90468 = () => interopDefault(import('..\\pages\\employer-portal\\performance\\job-advert.vue' /* webpackChunkName: "pages/employer-portal/performance/job-advert" */))
const _67f7dcd7 = () => interopDefault(import('..\\pages\\employer-portal\\performance\\job-adverts.vue' /* webpackChunkName: "pages/employer-portal/performance/job-adverts" */))
const _47ca1695 = () => interopDefault(import('..\\pages\\employer-portal\\post-job-advert\\banner.vue' /* webpackChunkName: "pages/employer-portal/post-job-advert/banner" */))
const _1f16c9e0 = () => interopDefault(import('..\\pages\\employer-portal\\post-job-advert\\banner-preview.vue' /* webpackChunkName: "pages/employer-portal/post-job-advert/banner-preview" */))
const _73007456 = () => interopDefault(import('..\\pages\\employer-portal\\post-job-advert\\text.vue' /* webpackChunkName: "pages/employer-portal/post-job-advert/text" */))
const _41b17ae4 = () => interopDefault(import('..\\pages\\public\\job-adverts\\banner\\_slug.vue' /* webpackChunkName: "pages/public/job-adverts/banner/_slug" */))
const _d17c885c = () => interopDefault(import('..\\pages\\employer-portal\\form-templates\\_slug.vue' /* webpackChunkName: "pages/employer-portal/form-templates/_slug" */))
const _3e57554e = () => interopDefault(import('..\\pages\\employer-portal\\recruitment-advice\\_slug.vue' /* webpackChunkName: "pages/employer-portal/recruitment-advice/_slug" */))
const _02fc2a3c = () => interopDefault(import('..\\pages\\public\\career-advice\\_slug.vue' /* webpackChunkName: "pages/public/career-advice/_slug" */))
const _0f537ad1 = () => interopDefault(import('..\\pages\\public\\employers\\_slug.vue' /* webpackChunkName: "pages/public/employers/_slug" */))
const _aacc2e8c = () => interopDefault(import('..\\pages\\public\\job-adverts\\_slug.vue' /* webpackChunkName: "pages/public/job-adverts/_slug" */))
const _12817f0c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d4e6ae84,
    alias: ["/about-us","/higher/about","/further/about","/school/about"]
  }, {
    path: "/career-advice",
    component: _00388c2a,
    alias: ["/higher/career-advice","/further/career-advice","/school/career-advice"]
  }, {
    path: "/contact",
    component: _67824591,
    alias: ["/contact-us","/higher/contact","/further/contact","/school/contact"]
  }, {
    path: "/employer-portal",
    component: _5cae0c0a,
    name: "employer-portal___en"
  }, {
    path: "/employers",
    component: _110a1c19,
    alias: ["/higher/employers","/further/employers","/school/employers"]
  }, {
    path: "/faq",
    component: _bd20d3f2,
    alias: ["/higher/faq","/further/faq","/school/faq"]
  }, {
    path: "/job-adverts",
    component: _a75eebfc,
    alias: ["/higher/job-adverts","/further/job-adverts","/school/job-adverts"]
  }, {
    path: "/auth/employer",
    component: _644405c9,
    name: "auth-employer___en"
  }, {
    path: "/auth/job-seeker",
    component: _5fc7188f,
    name: "auth-job-seeker___en"
  }, {
    path: "/career-advice/write",
    component: _46f12692,
    alias: ["/higher/career-advice/write","/further/career-advice/write","/school/career-advice/write"]
  }, {
    path: "/employer-portal/account",
    component: _0bdc5fe5,
    name: "employer-portal-account___en"
  }, {
    path: "/employer-portal/basket",
    component: _3a7c63be,
    name: "employer-portal-basket___en"
  }, {
    path: "/employer-portal/checkout",
    component: _ef1dc184,
    name: "employer-portal-checkout___en"
  }, {
    path: "/employer-portal/form-templates",
    component: _ce0f45cc,
    name: "employer-portal-form-templates___en"
  }, {
    path: "/employer-portal/info",
    component: _0f1572a6,
    name: "employer-portal-info___en"
  }, {
    path: "/employer-portal/messaging-center",
    component: _855aed94,
    name: "employer-portal-messaging-center___en"
  }, {
    path: "/employer-portal/notifications",
    component: _43f5dd20,
    name: "employer-portal-notifications___en"
  }, {
    path: "/employer-portal/post-job-advert",
    component: _0536ad0e,
    name: "employer-portal-post-job-advert___en"
  }, {
    path: "/employer-portal/pricing",
    component: _351b00fe,
    name: "employer-portal-pricing___en"
  }, {
    path: "/employer-portal/profile",
    component: _3a597d3e,
    name: "employer-portal-profile___en"
  }, {
    path: "/employer-portal/purchase",
    component: _7952fd0e,
    name: "employer-portal-purchase___en"
  }, {
    path: "/employer-portal/recruitment-advice",
    component: _3aea12be,
    name: "employer-portal-recruitment-advice___en"
  }, {
    path: "/job-seeker/application-details",
    component: _7acf142c,
    name: "job-seeker-application-details___en"
  }, {
    path: "/job-seeker/applications",
    component: _365ee064,
    name: "job-seeker-applications___en"
  }, {
    path: "/job-seeker/apply",
    component: _370c9cd3,
    name: "job-seeker-apply___en"
  }, {
    path: "/job-seeker/dashboard",
    component: _6ab25279,
    name: "job-seeker-dashboard___en"
  }, {
    path: "/job-seeker/documents",
    component: _1827eec6,
    name: "job-seeker-documents___en"
  }, {
    path: "/job-seeker/job-alerts",
    component: _7bdb0412,
    name: "job-seeker-job-alerts___en"
  }, {
    path: "/job-seeker/messaging-center",
    component: _af3d922e,
    name: "job-seeker-messaging-center___en"
  }, {
    path: "/job-seeker/notifications",
    component: _38ce444d,
    name: "job-seeker-notifications___en"
  }, {
    path: "/job-seeker/profile",
    component: _5f1fce4e,
    name: "job-seeker-profile___en"
  }, {
    path: "/job-seeker/wishlist",
    component: _2ce840f0,
    name: "job-seeker-wishlist___en"
  }, {
    path: "/policy/cookies",
    component: _47f34c0e,
    alias: ["/higher/policy/cookies","/further/policy/cookies","/school/policy/cookies"]
  }, {
    path: "/policy/privacy",
    component: _07380887,
    alias: ["/higher/policy/privacy "," /further/policy/privacy ","/school/policy/privacy"]
  }, {
    path: "/policy/terms",
    component: _325084c6,
    alias: ["/higher/policy/privacy","/further/policy/terms","/school/policy/terms"]
  }, {
    path: "/public/about",
    component: _d4e6ae84,
    name: "public-about___en"
  }, {
    path: "/public/career-advice",
    component: _00388c2a,
    name: "public-career-advice___en"
  }, {
    path: "/public/contact",
    component: _67824591,
    name: "public-contact___en"
  }, {
    path: "/public/employers",
    component: _110a1c19,
    name: "public-employers___en"
  }, {
    path: "/public/faq",
    component: _bd20d3f2,
    name: "public-faq___en"
  }, {
    path: "/public/job-adverts",
    component: _a75eebfc,
    name: "public-job-adverts___en"
  }, {
    path: "/auth/employer/activation",
    component: _734a18af,
    name: "auth-employer-activation___en"
  }, {
    path: "/auth/employer/forget-password",
    component: _8cbaa79c,
    name: "auth-employer-forget-password___en"
  }, {
    path: "/auth/employer/reset-password",
    component: _622f7ef2,
    name: "auth-employer-reset-password___en"
  }, {
    path: "/auth/employer/success-registration",
    component: _f17bd3c8,
    name: "auth-employer-success-registration___en"
  }, {
    path: "/auth/job-seeker/activation",
    component: _3ac1d1a9,
    name: "auth-job-seeker-activation___en"
  }, {
    path: "/auth/job-seeker/change-email",
    component: _a33c9edc,
    name: "auth-job-seeker-change-email___en"
  }, {
    path: "/auth/job-seeker/forget-password",
    component: _aa4d0110,
    name: "auth-job-seeker-forget-password___en"
  }, {
    path: "/auth/job-seeker/reset-password",
    component: _7663aa28,
    name: "auth-job-seeker-reset-password___en"
  }, {
    path: "/auth/job-seeker/success-registration",
    component: _6dca5e96,
    name: "auth-job-seeker-success-registration___en"
  }, {
    path: "/employer-portal/applications/dashboard",
    component: _6ebca9c9,
    name: "employer-portal-applications-dashboard___en"
  }, {
    path: "/employer-portal/applications/details",
    component: _15ba2db7,
    name: "employer-portal-applications-details___en"
  }, {
    path: "/employer-portal/applications/tracking-board",
    component: _444b2a6b,
    name: "employer-portal-applications-tracking-board___en"
  }, {
    path: "/employer-portal/edit-job-advert/banner",
    component: _d9d66c2a,
    name: "employer-portal-edit-job-advert-banner___en"
  }, {
    path: "/employer-portal/edit-job-advert/text",
    component: _0aec052c,
    name: "employer-portal-edit-job-advert-text___en"
  }, {
    path: "/employer-portal/form-templates/edit",
    component: _1b96baf2,
    name: "employer-portal-form-templates-edit___en"
  }, {
    path: "/employer-portal/manage-job-advert/banner-adverts",
    component: _5d227982,
    name: "employer-portal-manage-job-advert-banner-adverts___en"
  }, {
    path: "/employer-portal/manage-job-advert/text-advert-details",
    component: _d83e81d6,
    name: "employer-portal-manage-job-advert-text-advert-details___en"
  }, {
    path: "/employer-portal/manage-job-advert/text-adverts",
    component: _6a511dfa,
    name: "employer-portal-manage-job-advert-text-adverts___en"
  }, {
    path: "/employer-portal/performance/banner-advert",
    component: _2064f25d,
    name: "employer-portal-performance-banner-advert___en"
  }, {
    path: "/employer-portal/performance/banner-adverts",
    component: _20360b34,
    name: "employer-portal-performance-banner-adverts___en"
  }, {
    path: "/employer-portal/performance/job-advert",
    component: _bfb90468,
    name: "employer-portal-performance-job-advert___en"
  }, {
    path: "/employer-portal/performance/job-adverts",
    component: _67f7dcd7,
    name: "employer-portal-performance-job-adverts___en"
  }, {
    path: "/employer-portal/post-job-advert/banner",
    component: _47ca1695,
    name: "employer-portal-post-job-advert-banner___en"
  }, {
    path: "/employer-portal/post-job-advert/banner-preview",
    component: _1f16c9e0,
    name: "employer-portal-post-job-advert-banner-preview___en"
  }, {
    path: "/employer-portal/post-job-advert/text",
    component: _73007456,
    name: "employer-portal-post-job-advert-text___en"
  }, {
    path: "/public/career-advice/write",
    component: _46f12692,
    name: "public-career-advice-write___en"
  }, {
    path: "/public/policy/cookies",
    component: _47f34c0e,
    name: "public-policy-cookies___en"
  }, {
    path: "/public/policy/privacy",
    component: _07380887,
    name: "public-policy-privacy___en"
  }, {
    path: "/public/policy/terms",
    component: _325084c6,
    name: "public-policy-terms___en"
  }, {
    path: "/public/job-adverts/banner/:slug",
    component: _41b17ae4,
    name: "public-job-adverts-banner-slug___en"
  }, {
    path: "/employer-portal/form-templates/:slug",
    component: _d17c885c,
    name: "employer-portal-form-templates-slug___en"
  }, {
    path: "/employer-portal/recruitment-advice/:slug",
    component: _3e57554e,
    name: "employer-portal-recruitment-advice-slug___en"
  }, {
    path: "/public/career-advice/:slug?",
    component: _02fc2a3c,
    name: "public-career-advice-slug___en"
  }, {
    path: "/public/employers/:slug",
    component: _0f537ad1,
    name: "public-employers-slug___en"
  }, {
    path: "/public/job-adverts/:slug?",
    component: _aacc2e8c,
    name: "public-job-adverts-slug___en"
  }, {
    path: "/career-advice/:slug",
    component: _02fc2a3c,
    alias: ["/higher/career-advice/:slug","/further/career-advice/:slug","/school/career-advice/:slug"]
  }, {
    path: "/employers/:slug",
    component: _0f537ad1,
    alias: ["/higher/employers/:slug","/further/employers/:slug","/school/employers/:slug"]
  }, {
    path: "/job-adverts/:slug",
    component: _aacc2e8c,
    alias: ["/higher/job-adverts/:slug","/further/job-adverts/:slug","/school/job-adverts/:slug"]
  }, {
    path: "/",
    component: _12817f0c,
    name: "index___en"
  }, {
    path: "/",
    component: _12817f0c,
    alias: ["/higher","/further","/school"],
    beforeEnter: function(to, from, next) {
          const image = new Image();
          image.src = './images/e/higher.webp';
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
