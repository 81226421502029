//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderSection from '~/components/common/layout/HeaderSection';
import FooterSection from '~/components/common/layout/FooterSection';
import MobileMenu from '~/components/common/layout/MobileMenu';
import LogoutToLogin from '~/components/auth/LogoutToLogin';

import {
    scrollTop,
    stickyHeaderHandler,
    resizeHandler,
    showScrollTopHandler,
} from '~/utilities/browser.js';

export default {
    head() {
        return {
            link: [
                {
                    rel: 'stylesheet',
                    href: '/css/shared.css',
                    async: true,
                    defer: true,
                },
                {
                    rel: 'stylesheet',
                    href:
                        '/css/layout/' +
                        (this.extrabase ? this.extrabase : 'higher') +
                        '.css',
                    async: true,
                    defer: true,
                },
            ],
        };
    },
    data: function () {
        return {
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher',
        };
    },
    components: {
        HeaderSection,
        FooterSection,
        MobileMenu,
        LogoutToLogin,
    },
    watch: {
        $route: function () {
            resizeHandler();
            this.$modal.hideAll();
            this.$extrabaseLogic();
            this.extrabase = localStorage.getItem('extrabase');
        },
    },
    mounted: function () {
        window.addEventListener('scroll', stickyHeaderHandler, {
            passive: true,
        });
        window.addEventListener('scroll', showScrollTopHandler, {
            passive: true,
        });
        window.addEventListener('resize', stickyHeaderHandler);
        window.addEventListener('resize', resizeHandler);
        this.$extrabaseLogic();
        this.extrabase = localStorage.getItem('extrabase');
    },
    destroyed: function () {
        window.removeEventListener('scroll', showScrollTopHandler, {
            passive: true,
        });
        window.removeEventListener('scroll', stickyHeaderHandler, {
            passive: true,
        });
        window.removeEventListener('resize', stickyHeaderHandler);
        window.removeEventListener('resize', resizeHandler);
    },
    methods: {
        scrollToTop: function () {
            scrollTop(false);
        },
        hideMobileSearch: function () {
            if (document.querySelector('.header-search.header-search-inline')) {
                let headerSearch = document.querySelector(
                    '.header-search.header-search-inline'
                );
                headerSearch.classList.remove('show');
                headerSearch
                    .querySelector('.header-search-wrapper')
                    .classList.remove('show');
            }
            if (document.querySelector('.search-suggests')) {
                document
                    .querySelector('.search-suggests')
                    .setAttribute('style', 'display: none');
            }
        },
    },
};
