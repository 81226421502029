import axios from 'axios';

export const baseUrl = 'https://api.edyougo.com/';
const customHeader = {
    'X-Requested-With': 'XMLHttpRequest',
    'withCredentials': true,
    'Access-Control-Allow-Origin': "*",
    "X-XSRF-TOKEN": localStorage.getItem("x-xsrf-token")
};

let instance = axios.create({
    baseUrl,
    headers: customHeader,
    withCredentials: true,
});

instance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response.status == 401 || error.response.status == 403) {
            localStorage.clear();
            if (window.location.pathname.includes("/employer-portal/")) {
                localStorage.setItem('pathname_employer', window.location.origin + window.location.pathname + window.location.search)
                window.location.replace("/auth/employer/");
            } else {
                window.location.replace("/auth/job-seeker/");
                localStorage.setItem('pathname_seeker', window.location.origin + window.location.pathname + window.location.search)
            }

        }
        return error.response;
    }
);

export function prepareFormData(data) {
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        let value = data[key] // value of the current key
        if (value) { formData.append(key, value); };
    })
    return formData;
}

export function prepareFormEncodingData(data) {
    const params = new URLSearchParams();
    Object.keys(data).forEach(key => {
        let value = data[key] // value of the current key
        if (value) { params.append(key, value); };
    });
    return params;
}

export function parseApiResponse(response) {
    if (response.headers["x-xsrf-token"]) {
        localStorage.setItem("x-xsrf-token", response.headers["x-xsrf-token"])
    }

    if (response.status == 400) {
        var messages = response.data.Messages;
        if (messages)
            var errors = response.data.errors;
        if (errors) {
            for (const k in errors) {
                Vue.$toast.open({
                    message: "Technical Issue: " + errors[k],
                    type: 'error',
                });
            }
        }

        const data1 = response.data.Data;
        const messages1 = response.data.Messages;
        const success1 = false; // response.data.Success;
        return {
            success: false,
            code: response.status,
            messages: messages1
        }
    } else if (response.status == 401) {
        localStorage.clear();
        if (window.location.pathname.includes("/employer-portal/")) {
            localStorage.setItem('pathname_employer', window.location.origin + window.location.pathname + window.location.search)
            window.location.replace("/auth/employer/");
        } else {
            window.location.replace("/auth/job-seeker/");
            localStorage.setItem('pathname_seeker', window.location.origin + window.location.pathname + window.location.search)
        }
        return {
            success: false,
            code: response.status,
            messages: [],
        }
    } else if (response.status == 500) {
        Vue.$toast.open({
            message: "Technical Issue: 500",
            type: 'error',
        });
        const data1 = response.data.Data;
        const messages1 = response.data.Messages;
        const success1 = false; // response.data.Success;
        return {
            success: false,
            code: response.status,
            messages: [],
        }
    } else {
        const data = response.data.Data;
        const messages = response.data.Messages;
        const success = response.data.Success;
        return {
            data: data,
            messages: messages,
            success: success,
            code: response.status
        }
    }
}

export function httpErrorCodeResponse(httpCode) {
    let path = '/error';
    switch (httpCode) {
        case 401:
            path = '/';
            break;
        case 403:
            path = '/unauth';
            break;
        case 404:
            path = '/404';
            break;
    }
    router.push(path);
}


export async function GetReq(reqUrl, reqData = {}, extraHeader = {}, toSelect2 = false) {
    var response = await HTTP.get(baseUrl + reqUrl, { params: reqData }, extraHeader);
    const R = parseApiResponse(response);

    if (toSelect2 && R && R.data && R.data.DataSource) {
        var r = [];
        for (let i = 0; i < R.data.DataSource.length; i++) {
            r.push({
                id: R.data.DataSource[i].Value,
                text: R.data.DataSource[i].Text,
                more: R.data.DataSource[i].MoreData ? R.data.DataSource[i].MoreData.IsOther : false,
            });
        }
        return r;
    } else {
        return R;
    }
}

export async function PostReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.post(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}

export async function PutReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.put(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}

export async function DeleteReq(reqUrl, reqData, extraHeader = {}) {
    var response = await HTTP.delete(baseUrl + reqUrl, reqData, extraHeader);
    const R = parseApiResponse(response);
    return R;
}

export function remoteIdentifier(identifier, scope = "", ParentIds = null, multiple = false, filter = {}, objVal = false) {
    var fstr = "";
    if (Object.keys(filter).length > 0) {
        var i = 0;
        for (const property in filter) {
            fstr += 'Filters[' + i + '].Field=' + property + '&Filters[' + i + '].Value=' + filter[property] + "&";
            i++;
        }
    }
    return {
        allowClear: true,
        ajax: {
            url: baseUrl + 'data-sources' + scope + '/get-reference-data-source',
            xhrFields: {
                withCredentials: true
            },
            dataType: 'json',
            data: function(params) {
                var data = {
                    Identifier: identifier,
                    Query: params.term,
                    Page: params.page || 1,
                }
                if (ParentIds) {
                    data['ParentIds[0]'] = ParentIds
                }
                if (Object.keys(filter).length > 0) {
                    var i = 0;
                    for (const property in filter) {
                        data['Filters[' + i + '].Field'] = property;
                        data['Filters[' + i + '].Value'] = filter[property];
                        i++;
                    }
                }
                return data
            },
            processResults: function(data, params) {
                params.Page = params.page || 1;
                var r = [];
                for (let i = 0; i < data.Data.DataSource.length; i++) {
                    r.push({
                        id: !objVal ? data.Data.DataSource[i].Value : JSON.stringify({ id: data.Data.DataSource[i].Value, text: data.Data.DataSource[i].Text, moreData: data.Data.DataSource[i].MoreData }),
                        text: data.Data.DataSource[i].Text,
                        moreData: data.Data.DataSource[i].MoreData,
                        disabled: data.Data.DataSource[i].MoreData && data.Data.DataSource[i].MoreData.IsDowngraded
                    });
                }
                return {
                    results: r,
                    pagination: {
                        more: params.Page * data.Data.PageSize <
                            data.Data.TotalRecords,
                    },
                };
            },
            cache: true,
        },
        multiple: multiple,
        data: [{
            id: 1,
            text: "test"
        }]
    }
}

export async function logout(seeker, redirect = null) {
    this.$PostReq('account/' + (seeker ? 'job-seeker' : 'employer') + '/logout', {}).then(() => {
        localStorage.clear();
        location.href = redirect ? redirect : (seeker ? '/' : '/employer-portal/');
    });
}

export function sucmsg(context, messages = ["Done Successfully"], title = null) {
    context.$modal.show(() =>
        import ('~/components/features/modal/MessagesModal'), {
            messages: messages,
            title: title,
        }
    );
}

export function confmsg(context, title, msg, op, payload) {
    context.$modal.show(
        () =>
        import ('~/components/features/modal/ConfirmationModal'), {
            title: title,
            msg: msg,
            op: op,
            payload: payload
        }
    );
}

export const HTTP = instance;