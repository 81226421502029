//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data: function() {
        return {
            ads: []
        };
    },
    props: {
        location: {
            type: String,
            default: 'home'
        }
    },
    mounted() {
        this.getUpperAds();
    },
    methods: {
        async getUpperAds() {
            var R = await this.$GetReq('banner-advert/' + this.location, {
                Target: 0,
                InstitutionCategory: this.$getInstitutionCategoryId()
            });
            for (var i = 0; i < R.data.length; i++) {
                if (R.data[i].Target == 0) {
                    this.ads.push(R.data[i]);
                }
            }
        },
        async bannerClick(adId) {
            if (!adId) {
                return;
            }
            var R = await this.$PostReq('banner-advert/click', {
                Id: adId
            });
            if (R && R.data) {
                window.open(R.data, '_blank');
            }
        }
    }
};
