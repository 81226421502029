export function getInstitutionCategoryId() {
    var eb = localStorage.getItem('extrabase');
    if (!eb) {
        eb = 'higher';
    }
    var t =
        $nuxt.$route.path == '/' ?
        'higher' :
        $nuxt.$route.path.startsWith('/further') ?
        'further' :
        $nuxt.$route.path.startsWith('/school') ?
        'school' :
        $nuxt.$route.path.startsWith('/higher') ?
        'higher' :
        eb ?
        eb :
        'higher';
    return t == 'higher' ? 1 : t == 'further' ? 2 : t == 'school' ? 3 : 1;
}

export function extrabaseLogic() {
    var eb = localStorage.getItem('extrabase');
    if (!eb) {
        eb = 'higher';
    }
    var t =
        $nuxt.$route.path == '/' ?
        'higher' :
        $nuxt.$route.path.startsWith('/further') ?
        'further' :
        $nuxt.$route.path.startsWith('/school') ?
        'school' :
        $nuxt.$route.path.startsWith('/higher') ?
        'higher' :
        eb ?
        eb :
        'higher';

    if ($nuxt.$route.query.ic == 1) {
        t = 'higher';
        localStorage.setItem('extrabase', t);
    }
    if ($nuxt.$route.query.ic == 2) {
        t = 'further';
        localStorage.setItem('extrabase', t);
    }
    if ($nuxt.$route.query.ic == 3) {
        t = 'school';
        localStorage.setItem('extrabase', t);
    }

    localStorage.setItem('extrabase', t);
}

export function getInstitutionCategoryUrl() {
    var eb = localStorage.getItem('extrabase');
    return eb ? "/" + eb : "";
}
export function foc(id = "acbtn") {
    document.getElementById(id).focus();
}

export function lg() {
    const w = window.innerWidth;
    return w >= 992;
}
export function isEmployer() {
    return localStorage.getItem('employer');
}
export function isSeeker() {
    return localStorage.getItem('seeker');
}
export function canAccess(permissionId, bool = true) {
    const lf = localStorage.getItem('features');
    if (!lf) return false;
    const features = JSON.parse(lf);
    if (!features) return false;
    var featuresObj = {};
    features.forEach(
        function(f) {
            featuresObj[f.Feature.Value + ''] = f.Value;
        }.bind(this)
    );

    if (bool)
        return (featuresObj && permissionId in featuresObj) ? true : false
    return (featuresObj && permissionId in featuresObj) ? featuresObj[permissionId] : 0
}
export function canAccessProduct(features, permissionId) {
    if (!features) return false;
    var featuresObj = {};
    features.forEach(
        function(f) {
            featuresObj[f.Feature.Value + ''] = true; //f.Value;
        }.bind(this)
    );
    return (featuresObj && permissionId in featuresObj) ?
        featuresObj[permissionId] : false
}

export function ckconfig() {
    return {
        removePlugins: 'elementspath',
        extraPlugins: 'copyformatting,showblocks,colorbutton,bidi,print,selectall,a11yhelp,basicstyles,blockquote,clipboard,listblock,format,horizontalrule,htmlwriter,wysiwygarea,indent,indentlist,list,magicline,maximize,pastetext,pastetools,pastefromgdocs,pastefromlibreoffice,pastefromword,removeformat,showborders,sourcearea,specialchar,menubutton,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,exportpdf,autogrow,textwatcher,textmatch,tableresize,justify,font,find,autolink,liststyle,indentblock,preview',
        toolbar: [{
                name: 'document',
                items: [
                    'ExportPdf',
                    'Preview',
                    'Print',
                ]
            },
            {
                name: 'clipboard',
                items: [
                    'Cut',
                    'Copy',
                    //'Paste', 
                    '-',
                    'Undo',
                    'Redo'
                ]
            },
            {
                name: 'editing',
                items: [
                    'Find',
                    'Replace',
                    '-',
                    'SelectAll',
                    '-',
                    'Scayt'
                ]
            },
            '/',
            {
                name: 'basicstyles',
                items: [
                    'Bold',
                    'Italic',
                    'Underline', //
                    'Strike',
                    'Subscript',
                    'Superscript',
                    '-',
                    'CopyFormatting',
                    'RemoveFormat'
                ]
            },
            {
                name: 'paragraph',
                items: [
                    'NumberedList',
                    'BulletedList',
                    '-',
                    'Outdent',
                    'Indent',
                    '-',
                    'Blockquote',
                    '-',
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    'JustifyBlock',
                    '-',
                    'BidiLtr',
                    'BidiRtl',
                ]
            },
            '/',
            { name: 'insert', items: ['Table', 'HorizontalRule'] },

            {
                name: 'styles',
                items: ['Styles', 'Format', 'Font', 'FontSize']
            },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        ],
        removeButtons: ''
    }
};

export function youtube_parser(url) {
    var video_id = url.split('v=')[1];
    if (!video_id) {
        if (url.includes("youtu.be")) {
            url = url.split('?')[0];
            url = url.split('/');
            video_id = url[url.length - 1];
            return video_id;
        }
        return;
    }
    var ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }
    return video_id;
}