//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainMenu from '~/components/common/layout/MainMenu';
document.querySelector('body').classList.add('loaded');

export default {
    components: {
        MainMenu
    },
    data: function() {
        return {
            isHome: {
                type: Boolean,
                default: true
            },
            isFullwidth: {
                type: Boolean,
                default: false
            },
            uri: window.location.pathname,

            fullName: localStorage.getItem('fullName'),
            forename: localStorage.getItem('forename'),
            chatCount: 0,
            wishlistCount: 0,
            jobAlertCount: 0,
            notificationCount: 0,
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher',
            mounted: false,
            done: false
        };
    },
    computed: {
        emp() {
            return window.location.href.indexOf('/employer-porta') > -1;
        }
    },
    watch: {
        $route: function() {
            this.uri = window.location.pathname;
            if (this.$route.path.includes('fullwidth')) {
                this.isFullwidth = true;
            } else this.isFullwidth = false;

            this.$extrabaseLogic();
            this.extrabase = localStorage.getItem('extrabase');
        }
    },
    mounted: function() {
        this.mounted = true;
        if (this.$route.path.includes('fullwidth')) {
            this.isFullwidth = true;
        } else this.isFullwidth = false;
        if (localStorage.getItem('email')) {
            window.setInterval(() => {
                if (this.$isSeeker()) {
                    this.getChatCount();
                    this.getWishlistCount();
                    this.getJobAlertCount();
                    this.getNotificationsCount();
                }
            }, 30000);
        }
        this.$extrabaseLogic();
        this.extrabase = localStorage.getItem('extrabase');

        const myImage = new Image();
        if (this.extrabase == 'further')
            myImage.src = '@/static/images/e/higher.webp';
        else if (this.extrabase == 'school')
            myImage.src = '@/static/images/e/school.webp';
        else myImage.src = '@/static/images/e/higher.webp';
        myImage.onload = () => {
            this.done = true;
        };
        myImage.onerror = () => {
            this.done = true;
        };
    },
    methods: {
        showMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        },
        showMobileSearch: function(e) {
            let headerSearch = e.currentTarget.closest('.header-search');
            headerSearch.classList.add('show');
            headerSearch
                .querySelector('.header-search-wrapper')
                .classList.add('show');
        },

        async getChatCount() {
            var R = await this.$GetReq(
                'in-app-conversation/new-messages-count'
            );
            if (R) {
                if (R.success) {
                    this.chatCount = R.data;
                }
            }
        },
        async getWishlistCount() {
            var R = await this.$GetReq('job-seeker/wishlist/count');
            if (R) {
                if (R.success) {
                    this.wishlistCount = R.data;
                }
            }
        },
        async getJobAlertCount() {
            var R = await this.$GetReq('job-seeker/job-alert/count');
            if (R) {
                if (R.success) {
                    this.jobAlertCount = R.data;
                }
            }
        },
        async getNotificationsCount() {
            // var R = await this.$GetReq(
            //     'job-seeker/notification-log/new-notifications-count'
            // );
            // if (R) {
            //     if (R.success) {
            //         this.notificationCount = R.data;
            //     }
            // }
        }
    }
};
