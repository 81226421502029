
import { GetReq, PostReq, PutReq, DeleteReq, remoteIdentifier, logout, sucmsg, confmsg } from "~/utilities/network";
export default (context, inject) => {
    inject('GetReq', GetReq);
    inject('PostReq', PostReq);
    inject('PutReq', PutReq);
    inject('DeleteReq', DeleteReq);
    inject('remoteIdentifier', remoteIdentifier);
    inject('logout', logout);
    inject('sucmsg', sucmsg);
    inject('confmsg', confmsg);
}